header {
  background-color: transparent;
  // background-image: url("../assets/DSC_6829_WEB_darkened.jpg");
  background-position: bottom;
  background-size: cover;

  position: relative;
  font-family: Poppins;

  color: #222;
}
a {
  &:active,
  &:hover,
  &:link,
  &:visited {
    color: #222;
    text-decoration: none;
  }
}

// section {
//     width: 100%;
//     max-width: 1000px;
// }

.Title {
  font-size: 3rem;
  white-space: nowrap;

  margin-bottom: 3rem;

  &.title-sm {
    font-size: 3rem;
  }
}
.Subtitle {
  font-size: 2.5rem;
  color: var(--colour-accent);
  position: absolute;
  position: absolute;
  left: 127px;
  top: 97px;
}

.BigSection {
  padding: 3rem 0;
}

.TitleSection {
  padding-top: 5rem;
  padding-bottom: 3rem;
  height: 600px;

  // background-image: url('../assets/DSC_6553_WEB.jpg');
  background-image: url("../assets/DSC_6623_WEB.jpg");

  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.SalonPicture {
  background-image: url("../assets/Ivy-Creations-Salon-1500x630.jpg");
  background-position: center;
  flex-basis: 50vw;
  width: 50vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.AboutUs {
  background-color: var(--colour-accent);
  flex-basis: 50vw;
  width: 50vw;
  padding: 5rem;
  box-sizing: border-box;
}

.AboutUsBlock {
  font-family: "Cormorant Garamond";
}

.primary-colour {
  color: var(--colour-accent);
}

.text-muted {
  color: #999;
}

.StyleSection {
  p {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 768px) {
  .Title {
    font-size: 2rem;
  }

  .Subtitle {
    font-size: 1.5rem;
    left: 69px;
  }
}

section {
  display: grid;
  grid-template-columns: 1fr 1000px 1fr;
  width: 100%;
}

@media screen and (max-width: 768px) {

  .TitleSection {
    background-position-y: bottom;
    height: 284px;
  }

  section {
    grid-template-columns: 1rem 1fr 1rem;
  }
}

.PictureGrid {
  background-color: #fcfcfc;
  display: grid;
  gap: .5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  img {
    max-width: 100%;
    height: auto;
  }
}
