html,
body,
#root {
  margin: 0;
  padding: 0;

  font-family: Poppins, -apple-system, serif;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  flex: 1;
  color: #141414;

  align-items: center;
  flex: 1 1 100%;
  width: 100%;
  font-size: 18px;
}

:where(html) {
  --colour-accent: rgb(206, 177, 156);
}

.flex {
  display: flex;

  &.row {
    flex-direction: row;
  }
  &.column {
    flex-direction: column;
  }

  &.fill {
    flex: 1 1 auto;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.center-x {
    justify-content: center;
  }

  &.right-x {
    justify-content: flex-end;
  }

  &.center-y {
    align-items: center;
  }

  &.right-y {
    align-items: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }
  &.fixed {
    flex: 0 0 auto;
  }


  .gap {
    gap: 1rem;
  }
}

.Logo {
  margin: 4em 0;
}

.no-select {
  user-select: none;
}

.title-font {
  font-family: "Cormorant Garamond";
}

.relative {
  position: relative;
}