
.SocialsInner {
  .MainHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .SocialsInner {
    .MainHeader {
      flex-direction: column;
    }

    .Socials {
      margin-bottom: 1rem;
    }
  }
}